import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useNotifications } from "../contexts/notifications-provider";
import { isLoggedIn, checkClaim, isCenterAdmin } from "../utils/auth";
import Layout from "./layout/layout";

const PrivateRoute = ({ component: Component, claims, centerAdmin, ...rest }) => {
  const { addNotification } = useNotifications();
  const isAuthed = isLoggedIn();

  useEffect(() => {
    if (!isAuthed && !centerAdmin) {
      // If we’re not logged in, redirect to the login page.
      addNotification({
        type: 'info',
        body: "You must sign in to continue.",
      });
      navigate(`/sign-in?redir=${window.location.pathname}`, { replace: true });
    }
    if (centerAdmin && !isCenterAdmin()) {
      // This is a center admin route, and we are NOT logged in as a center admin...
      addNotification({
        type: 'info',
        body: "You must sign in to continue.",
      });
      navigate(`/dashboard?redir=${window.location.pathname}`, { replace: true });
    }
  }, [isAuthed, centerAdmin]);

  if (!isAuthed && !centerAdmin) {
    // navigate(`/signin`);
    return null;
  } else if (centerAdmin && !isCenterAdmin()) {
    return null;
  }
  if (claims && !checkClaim(claims)) {
    return (
      <Layout>
        <section className="">
          <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
            <div className="lg:w-2/6 md:w-1/2 flex text-center flex-col md:mx-auto w-full f">
              <h1 className="text-lg text-gray-700">Permission Denied.</h1>
            </div>
          </div>
        </section>
      </Layout>
    );
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
}


export default PrivateRoute