import React, { useEffect } from 'react';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import AutoForm from '../../../../form/auto-form';
import { formatDay } from '../../../../../utils/helpers';
import SEO from '../../../../layout/seo';

const AdminProduct = ({ productId, leagueId }) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    const breadcrumbs = [
      {
        href: '/app/admin/products',
        label: 'Products',
      },
    ];
    if (leagueId) {
      breadcrumbs.push({
        href: '/app/admin/products/choose-league',
        label: 'Choose League',
      });
      breadcrumbs.push({
        href: `/app/admin/products/league/create?id=${leagueId}`,
        label: 'Create Product',
      });
    } else {
      breadcrumbs.push({
        href: `/app/admin/product/edit?id=${productId}`,
        label: `Update Product`,
      });
    }
    setBreadcrumbs(breadcrumbs);
  }, [productId, leagueId]);


  const productForm = {
    type: 'post',
    fetchRoute: productId ? `/admin/product/${productId}` : `/admin/league/${leagueId}`,
    fetchErrorRedirect: '/admin/products',
    fetchErrorMessage: 'There was an error while attempting to load the requested product.',
    postRoute: `/admin/product${productId ? `/${productId}` : ''}`,
    beforePost: (values, actions, product) => {
      // Ensure we send the league id to the server
      if (leagueId) {
        return {
          ...values,
          type: 'league',
          league_id: leagueId,
        };
      } else {
        return {
          ...values,
          type: 'league',
          league_id: product.league_id,
        };
      }
    },
    successRedirect: '/app/admin/products',
    successNotification: 'The product was saved.',
    fields: [
      {
        name: 'center_id',
        label: 'Center',
        type: 'select-lookup',
        lookupUrl: '/options/centers/search',
        placeholder: 'Search centers by name',
        required: true,
      },
      {
        name: 'name',
        label: 'Product Name',
        type: 'text',
        required: true,
      },
      {
        name: 'short_description',
        label: 'Short Description',
        type: 'textarea',
        helpText: 'Will be used in lists and preview interfaces, as well as the top of full page displays.',
      },
      {
        name: 'description',
        label: 'Long Description',
        type: 'markdown',
        helpText: 'Full details about the league.',
      },
      {
        name: 'schedule_info',
        label: 'Schedule Info',
        type: 'textarea',
        helpText: 'Optional scheduling information to present to people registering.',
      },
      {
        name: 'price',
        label: 'Registration Price',
        type: 'text',
        required: true,
        helpText: 'Only include numbers, no commas or dollar signs.',
        initialValue: (product) => {
          return !!product ? product.price : '20.00';
        },
      },
      {
        name: 'session_fee',
        label: 'Session Fee',
        type: 'text',
        helpText: 'The fee a bowler can expect to pay at each league session. Only include numbers, no commas or dollar signs.',
        initialValue: (product) => {
          return !!product ? product.session_fee : '24.00';
        },
      },
      {
        name: 'min_age',
        label: 'Minimum Age',
        type: 'text',
        required: true,
        initialValue: (product) => {
          return !!product ? product.min_age : 21;
        },
      },
      {
        name: 'num_weeks',
        label: 'Number of Weeks',
        type: 'number',
        required: true,
        initialValue: (product) => {
          return !!product ? product.num_weeks : 9;
        },
      },
      {
        name: 'num_games',
        label: 'Games Per Week',
        type: 'number',
        required: true,
        initialValue: (product) => {
          return !!product ? product.num_games : 4;
        },
      },
      {
        name: 'team_size',
        label: 'Team Size',
        type: 'text',
        required: true,
        helpText: 'Whole numbers only. This is a required field for all new products.',
        initialValue: (product) => {
          return !!product ? product.team_size : '2';
        },
      },
      {
        name: 'total_stock',
        label: 'Total Stock',
        type: 'text',
        required: true,
        helpText: 'The number of products available for sale.',
        initialValue: (product) => {
          return !!product ? product.total_stock : '24';
        },
      },
      {
        name: 'start_date',
        label: 'Start Date',
        type: 'date',
        required: true,
        helpText: 'Enter the date that this league is scheduled to start.',
        initialValue: (product) => {
          const { start_date: startDate } = product || {};
          return startDate ? formatDay(startDate, 'MM/DD/Y') : null;
        },
      },
      {
        name: 'start_time',
        label: 'Start Time',
        type: 'time',
        required: true,
        helpText: 'Enter the time that this league is scheduled to start.',
        initialValue: (product) => {
          return !!product ? product.start_time : '6:30 PM';
        },
      },
      {
        name: 'registration_end_date',
        label: 'Last Day To Register',
        type: 'date',
        required: true,
        helpText: 'Enter the last day that people can register for this league.',
        initialValue: (product) => {
          const { registration_end_date: endDate } = product || {};
          return endDate ? formatDay(endDate, 'MM/DD/Y') : null;
        },
      },
      {
        name: 'image',
        type: 'file',
        label: 'Cover Photo',
        maxFiles: 1,
        isImage: true,
        multiple: false,
        helpText: 'Cover photo for the product. Should be roughly square dimensions.',
      },
      {
        name: 'banner_image',
        type: 'file',
        label: 'Banner Photo',
        maxFiles: 1,
        isImage: true,
        multiple: false,
        helpText: 'Banner photo for the products, displayed on Leagues listing page. Should be roughly square dimensions.',
      },
      {
        name: 'team_registrations',
        label: 'Team Registration Settings',
        type: 'checkbox',
        title: 'Only team captains can register',
        initialValue: (product) => {
          return !!product ? !!product.team_registrations : false;
        },
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'pending',
            label: 'Pending',
          },
          {
            value: 'active',
            label: 'Active',
          },
        ],
        required: true,
        initialValue: (product) => {
          return !!product ? product.status : 'active';
        },
      },
    ],
    submitLabel: `${productId ? 'Update' : 'Create'} Product`,
  };

  return (
    <AdminContainer>
      <SEO title={`${productId ? 'Update' : 'Create'} Product`} />
      <Heading title={`${productId ? 'Update' : 'Create'} Product`} />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...productForm} />
      </div>
    </AdminContainer>
  );
}

export default AdminProduct;
